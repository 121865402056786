import axios from 'axios';
import Audio from './audio';

class TextToSpeech {
    constructor(options) {
        const defaults = {
            itemId: 0,
            hash: '',
            containerSelector: '#tts-container',
            initButtonSelector: '#tts-initialize',
            playerTemplateSelector: '#tts-player',
        };
        this.params = Object.assign({}, defaults, options || {});

        this.container = document.querySelector(this.params.containerSelector);
        this.initButton = document.querySelector(this.params.initButtonSelector);
        this.playerTemplate = document.querySelector(this.params.playerTemplateSelector);
        this.initButtonText = this.initButton.querySelector('span');

        console.log(this.initButtonText);

        if (!this.params.itemId || !this.container || !this.initButton || !this.playerTemplate) {
            return;
        }

        this.start();
    }

    start() {

        this.initButton.addEventListener('click', (e) => {
            e.preventDefault();
            this.initialize();
        });

    }

    async initialize() {

        this.container.classList.add('loading');

        try {
            const response = await axios.post(`/index.php?option=com_news&task=item.tts&format=json`, {
                id: this.params.itemId,
                hash: this.params.hash
            });

            
            const clone = this.playerTemplate.content.cloneNode(true);
            // const player = clone.querySelector('audio');
            // player.src = response.data.data.url;
            
            this.initButton.parentNode.replaceChild(clone, this.initButton);

            
            new Audio({
                src: response.data.data.url,
                player: '#news-audio-' + this.params.itemId,
                start: true
            });

            
            // player.play();
        } catch (error) {
            this.initButtonText.textContent = 'Προσωρινό σφάλμα';
            console.log(error);
        } finally {
            this.container.classList.remove('loading');
        }
    }
}

export {
    TextToSpeech as
        default
};
